import "./phone-nav.styles.css";
import { NavLink } from "react-router-dom";

const PhoneNav = () => {
  return (
    <div className="phone-nav">
      <NavLink to="/">Home</NavLink>
      <NavLink to="/story">Our Story</NavLink>
      <NavLink to="/services">Services</NavLink>
      <NavLink to="/contact">Contact Us</NavLink>
      <NavLink to="/book">Book</NavLink>
    </div>
  );
};

export default PhoneNav;
