const ClientTestimonials = [
  {
    name: "Robert Enochs, Author of Vegan Diet Myths",
    description:
      "His guidance, enthusiasm, and commitment make him an invaluable resource, and I highly recommend his services to anyone ready to take control of their health and live their best life.",
  },
  {
    name: "Melissa Carter",
    description:
      "The holistic health assessment completely transformed my approach to wellness. The personalized recommendations were spot on!",
  },
  {
    name: "Daniel Foster",
    description:
      "Thanks to the tailored meal plans, I've developed healthy eating habits that improved my energy and overall well-being. Highly recommend!",
  },
  {
    name: "Sophia Ramirez",
    description:
      "The stress management tips and dietary guidance have been life-changing. I feel more balanced and in control of my health than ever before.",
  },
];

export default ClientTestimonials;
