import "./footer.styles.css";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { subscribe } from "../../utility/subscribe-api";
import { useState } from "react";
import useIsPhone from "../../hooks/useIsPhone";

const Footer = () => {
  const [email, setEmail] = useState("");
  const isPhone = useIsPhone();

  const handleSubmit = async (e) => {
    e.preventDefault();

    await subscribe({ email });

    setEmail("");
    e.target.reset();
  };

  return isPhone ? (
    <div className="footer">
      <div>
        <img src="/images/John-Ji Logo.svg" alt="John-Ji Logo" />
        <h3>© Copyright John-Ji Health and Wellness, 2024</h3>
      </div>
      <div className="footer-contact">
        <div className="footer-cntct-cntnr">
          <div>
            <h3>Start Your Journey</h3>
            <div className="footer-in">
              <form
                onSubmit={(e) => handleSubmit(e)}
                style={{ display: "flex" }}
              >
                <input
                  type="email"
                  placeholder="Your Email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button type="submit" className="subscribe">
                  Subscribe→
                </button>
              </form>
            </div>
          </div>
          <div>
            <h3>Email Us</h3>
            <a href="mailto:john@john-ji.com">john@john-ji.com</a>
          </div>
        </div>
        <div className="social-media">
          <div className="social-icon">
            <FaFacebookF
              size="2.3rem"
              fill="#fcf7ed"
              onClick={() =>
                (window.location.href =
                  "https://www.facebook.com/profile.php?id=100064777819559&mibextid=ZbWKwL")
              }
            />
          </div>
          <div className="social-icon">
            <FaInstagram
              size="2.3rem"
              fill="#fcf7ed"
              onClick={() =>
                (window.location.href =
                  "https://www.instagram.com/the.nourishment.guru/")
              }
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="footer">
      <div>
        <img src="/images/John-Ji Logo.svg" alt="John-Ji Logo" />
        <h3>© Copyright John-Ji Health and Wellness, 2024</h3>
      </div>
      <div className="footer-contact">
        <div>
          <h3>Start Your Journey</h3>
          <div className="footer-in">
            <form onSubmit={(e) => handleSubmit(e)} style={{ display: "flex" }}>
              <input
                type="email"
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <button type="submit" className="subscribe">
                Subscribe→
              </button>
            </form>
          </div>
        </div>
        <div>
          <h3>Email Us</h3>
          <a href="mailto:john@john-ji.com">john@john-ji.com</a>
        </div>
      </div>
      <div className="footer-links">
        <a href="/story">Our Story</a>
        <a href="/services">Services</a>
        <a href="/contact">Contact Us</a>
        <a href="/book">Book</a>
      </div>
      <div className="social-media">
        <div className="social-icon">
          <FaFacebookF
            size="2.3rem"
            fill="#fcf7ed"
            onClick={() =>
              (window.location.href =
                "https://www.facebook.com/profile.php?id=100064777819559&mibextid=ZbWKwL")
            }
          />
        </div>
        <div className="social-icon">
          <FaInstagram
            size="2.3rem"
            fill="#fcf7ed"
            onClick={() =>
              (window.location.href =
                "https://www.instagram.com/the.nourishment.guru/")
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
