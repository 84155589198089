import "./landing.styles.css";
import Nav from "../nav/nav.component";
import ServiceCard from "../service-card/service-card.component";
import Testimonials from "../testimonials/testimonials.component";
import Footer from "../footer/footer.component";

const Landing = () => {
  return (
    <>
      <Nav />
      <img
        src="/images/John-Ji Landing.webp"
        alt="John-Ji Landing"
        className="land-img"
      />
      <div className="svc-prev">
        <div className="ttl-cont">
          <div className="hr" />
          <h1>Services</h1>
          <div className="hr" />
        </div>
        <div className="svc-cards">
          <ServiceCard
            icon="/images/Nutrition.webp"
            title="Holistic Health Assessment"
            description="Evaluate your physical, mental, and emotional health, providing personalized nutritional insights and recommendations. Achieve balanced well-being through tailored dietary guidance and holistic care."
            bgColor="#d6cf64"
          />
          <ServiceCard
            icon="/images/Health.webp"
            title="Healthy Eating Habits"
            description="Adopt nutritious eating with our personalized meal plans and expert advice. Improve your health, manage weight, and enjoy better food choices with our support."
            bgColor="#839153"
          />
          <ServiceCard
            icon="/images/Stress Management 2.webp"
            title="Stress Management"
            description="Get expert advice and practical tips to manage stress through nutrition. We provide personalized dietary plans focused on stresss-reducing foods to help you achieve balance and tranquility."
            fontColor="#fcf7ed"
            bgColor="#50502c"
          />
        </div>
        <Testimonials />
        <Footer />
      </div>
    </>
  );
};

export default Landing;
