import "./nav.styles.css";
import { useState } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { CiMenuBurger } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";
import useIsPhone from "../../hooks/useIsPhone";
import PhoneNav from "../phone-nav/phone-nav.component";
import PopUp from "../pop-up/pop-up.component";

const Nav = () => {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const isPhone = useIsPhone();

  return isPhone ? (
    <div className="nav-cntnr">
      <div className="banner-bg">
        <a href="/book" className="banner-txt">
          Book a 15 minute free consultation
        </a>
      </div>
      <div className="nav">
        <img
          src="/images/John-Ji Logo.svg"
          alt="John-Ji Logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
        {clicked ? (
          <IoCloseOutline
            size={"2.6rem"}
            fill="#302402"
            onClick={() => setClicked(false)}
          />
        ) : (
          <CiMenuBurger
            size={"2.6rem"}
            fill="#302402"
            onClick={() => setClicked((prev) => !prev)}
          />
        )}
        {clicked ? <PhoneNav /> : ""}
      </div>
    </div>
  ) : (
    <div className="nav-cntnr">
      <PopUp />
      <div className="banner-bg">
        <a href="/book" className="banner-txt">
          Book a 15 minute free consultation
        </a>
      </div>
      <div className="nav">
        <NavLink
          to="/story"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Our Story
        </NavLink>
        <NavLink
          to="/services"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Services
        </NavLink>
        <img
          src="/images/John-Ji Logo.svg"
          alt="John-Ji Logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Contact Us
        </NavLink>
        <NavLink
          to="/book"
          className={({ isActive }) => (isActive ? "active" : "")}
        >
          Book
        </NavLink>
      </div>
    </div>
  );
};

export default Nav;
