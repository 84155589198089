import "./contact.styles.css";
import { useState } from "react";
import { sendEmail } from "../../utility/email-api";
import Nav from "../nav/nav.component";
import Footer from "../footer/footer.component";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      from: formData.name,
      email: formData.email,
      message: formData.message,
    };

    await sendEmail(data);

    setFormData({ name: "", email: "", message: "" });
    e.target.reset();
  };

  return (
    <>
      <Nav />
      <div className="contact-cntnr">
        <form onSubmit={(e) => handleSubmit(e)}>
          <h1>Contact Us</h1>
          <p>
            We're here to help! Whether you have questions, need support, or
            want to discuss your project, fill out the form below, and we'll get
            back to you.
          </p>
          <div className="form-field-cntnr">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              id="name"
              value={formData.name}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div className="form-field-cntnr">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={(e) => handleChange(e)}
              required
            />
          </div>
          <div className="form-field-cntnr">
            <label htmlFor="message">Message:</label>
            <textarea
              name="message"
              id="message"
              value={formData.message}
              onChange={(e) => handleChange(e)}
              style={{ height: "100px" }}
            />
          </div>
          <button type="submit" style={{ alignSelf: "flex-end" }}>
            Submit
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
