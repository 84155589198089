import axios from "axios";

async function subscribe(email) {
  try {
    const res = await axios.post(`${process.env.REACT_APP_URI}/subscribe`, email, {
      headers: { "Content-Type": "application/json" },
    });

    return res;
  } catch (e) {
    console.error(e);
    return e;
  }
}

export { subscribe };
