import "./book.styles.css";
import { useEffect } from "react";
import Nav from "../nav/nav.component";
import Footer from "../footer/footer.component";

const Book = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Nav />
      <div className="book">
        <div
          className="calendly calendly-inline-widget"
          data-url="https://calendly.com/john-john-ji/15min"
        />
      </div>
      <Footer />
    </>
  );
};

export default Book;
