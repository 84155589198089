import { useState, useEffect } from "react";

const useIsPhone = () => {
  const [isPhone, setIsPhone] = useState(
    window.matchMedia("(max-width: 600px)").matches
  );

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.matchMedia("(max-width: 600px)").matches);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isPhone;
};

export default useIsPhone;
